import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import tw, { styled } from "twin.macro";
// markup
const NotFoundPage = () => {
  return (
    <PageNotFoundWrapper>
      <StaticImage
        src={"../images/aaron-burden-ia0Zxx_sDr8-unsplash.jpg"}
        alt="A cross is under the blue sky."
        placeholder="blurred"
        layout="constrained"
        width={3840}
        loading="eager"
        className="hero-img"
      />
      <div className="pageNotFound-content">
        <h3>Sorry, this page no longer exists.</h3>
        <p>
          The page you’re looking for cannot be found. We suggest you go back to
          the home page.
        </p>
        <Link to="/">Back to Home</Link>
      </div>
    </PageNotFoundWrapper>
  );
};

export default NotFoundPage;

const PageNotFoundWrapper = styled.div`
  ${tw`h-full w-full flex flex-row justify-center`}
  background: rgb(51, 51, 51, 0.4);

  img {
    ${tw`mix-blend-multiply`}
    filter: grayscale(100%);
  }

  .pageNotFound-content {
    ${tw`absolute top-1/4 left-0 right-0 px-4 xl:left-1/3`}
  }

  .pageNotFound-content * {
    ${tw`text-white`}
  }

  .pageNotFound-content p {
    ${tw`mt-4 mb-8`}
  }

  .pageNotFound-content a {
    ${tw`block w-[200px] h-[40px] bg-active rounded text-center leading-[40px] active:shadow-none`}
    box-shadow: 8px 8px 24px 8px var(--gray-one);
  }
`;
